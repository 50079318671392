<template>
  <div class="!mb-[30px] md:!mb-[100px] md:mx-10">
    <h2 class="text-center text-3xl !mb-[70px] font-semibold">Skills</h2>
    <SkillList
      :skills="introSectionSkills"
      :numberOfShownSkills="numberOfShownSkills"
    />
    <NuxtLink
      v-if="showViewAllButton"
      to="/skills"
      class="w-[200px] flex btn bg-blue-600 border-blue-600 text-white mx-auto mt-[25px] md:mt-[80px] hover:bg-blue-700 hover:border-blue-700 hover:text-white tooltip"
    >
      Check all my skills
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { skills } from '~/constants'

import SkillList from './List.vue'

const introSkillSection: string[] = [
  'Vue.js',
  'React.js',
  'Angular',
  'TypeScript',
  'GraphQL',
  'Node.js',
  'Express.js',
  'MongoDB',
  'Firebase',
  'MySQL',
  'Tailwind',
  'SASS',
]

const introSectionSkills = computed(() =>
  skills.filter((skill) => introSkillSection.includes(skill.name))
)

defineProps<{
  showViewAllButton: boolean
  numberOfShownSkills: string
}>()
</script>
