<template>
  <div
    class="!grid grid-cols-3 justify-items-center md:grid-cols-3 md:gap-8 lg:grid-cols-4 xl:grid-cols-6 'mx-10"
  >
    <template v-for="(skill, idx) in skillsToShow" :key="idx">
      <SkillCard :skill="skill" :skillIndex="idx" />
    </template>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  import type { Skill } from '../../types/skill.type'

  const { skills, numberOfShownSkills } = defineProps<{
    skills: Skill[]
    numberOfShownSkills: string
  }>()

  const numberOfSkillsToShow = computed(() =>
    numberOfShownSkills === 'All'
      ? skills.length.toString()
      : numberOfShownSkills
  )

  const skillsToShow = computed(() =>
    skills.slice(0, parseInt(numberOfSkillsToShow.value))
  )
</script>
