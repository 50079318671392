<template>
  <div class="avatar">
    <div class="w-32 rounded-full mb-5">
      <img
        src="~/assets/images/nikola-ivanovic-profile-picture.jpg"
        alt="Nikola Ivanovic\'s profile picture"
      />
    </div>
  </div>
</template>
